.mt-10 {
    margin-top: 10px;
}
.mt-20 {
    margin-top: 20px;
}
.mt-30 {
    margin-top: 30px;
}
.mt-40 {
    margin-top: 40px;
}
.mt-50 {
    margin-top: 50px;
}
.mt-100 {
    margin-top: 100px;
}
.ml-10 {
    margin-left: 10px;
}
.ml-20 {
    margin-left: 20px;
}
.ml-30 {
    margin-left: 30px;
}
.ml-40 {
    margin-left: 40px;
}
.ml-50 {
    margin-left: 50px;
}
.mr-10 {
    margin-right: 10px;
}
.mr-20 {
    margin-right: 20px;
}
.mr-30 {
    margin-right: 30px;
}
.mr-40 {
    margin-right: 40px;
}
.mr-50 {
    margin-right: 50px;
}
.mb-10 {
    margin-bottom: 10px;
}
.mb-20 {
    margin-bottom: 20px;
}
.mb-30 {
    margin-bottom: 30px;
}
.mb-40 {
    margin-bottom: 40px;
}
.mb-50 {
    margin-bottom: 50px;
}
.mb-100 {
    margin-bottom: 100px;
}
.big-logo {
    height: 120px;
    width: 120px;
    display: inline-block;   
}
.center {
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 100%;
}
.page-heading {
    font-size: 24px;
    font-weight: 500;
    margin-top: 10px;
}
.ios-page-heading {
    font-size: 22px;
    font-weight: 500;
}
.page-padding {
    padding: 16px;
}
ul {
    padding-left: 20px;
}
.question {
    font-size: 18px !important;
    font-weight: 500 !important;
    color: #121212 !important;
}
.answer {
    font-size: 12px !important;
    font-weight: 400 !important;
    color: #121212 !important;
}
.qa-icon  {
    height: 80px;
    width: 80px;
    margin-top: -65px;
}
.btn-back {
}
.mp-0 {
    margin: 0px !important;
    padding: 0px !important;
}
.npo-name {
    color: #121212 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
}
.fwfs-700-14 {
    font-weight: 700 !important;
    font-size: 14px !important;
}
.fwfs-600-22 {
    font-weight: 600 !important;
    font-size: 22px !important;
}
.fwfs-600-18 {
    font-weight: 600 !important;
    font-size: 18px !important;
}
.fwfs-600-16 {
    font-weight: 600 !important;
    font-size: 16px !important;
}
.fwfs-500-24 {
    font-weight: 500 !important;
    font-size: 24px !important;
}
.fwfs-500-22 {
    font-weight: 500 !important;
    font-size: 22px !important;
}
.fwfs-500-20 {
    font-weight: 500 !important;
    font-size: 20px !important;
}
.fwfs-500-18 {
    font-weight: 500 !important;
    font-size: 18px;
}
.fwfs-500-16 {
    font-weight: 500 !important;
    font-size: 16px !important;
}
.fwfs-500-14 {
    font-weight: 500 !important;
    font-size: 14px !important;
}
.fwfs-500-12 {
    font-weight: 500 !important;
    font-size: 12px !important;
}
.fwfs-400-16 {
    font-weight: 400 !important;
    font-size: 16px !important;
}
.fwfs-400-14 {
    font-weight: 400 !important;
    font-size: 14px !important;
}
.fwfs-400-12 {
    font-weight: 400 !important;
    font-size: 12px !important;
}
.fwfs-400-10 {
    font-weight: 400 !important;
    font-size: 10px !important;
}

.truncate-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.truncate-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}