ion-radio {
    width: 30px;
    height: 30px;
  }
  
  ion-radio::part(container) {
    border-radius: 50%;
    border: 2px solid #ddd;
  }
  
  ion-radio::part(mark) {
    background: none;
    transition: none;
    transform: none;
    border-radius: 0;
  }
  
  ion-radio.radio-checked::part(container) {
    background: #F3BE4A;
    border-color: transparent;
  }
  
  ion-radio.radio-checked::part(mark) {
    width: 6px;
    height: 12px;
  
    border-width: 0px 2px 2px 0px;
    border-style: solid;
    border-color: #000;
  
    transform: rotate(45deg);
  }